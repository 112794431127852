import React from 'react';
import { Box, Grid } from '@mui/material';
import Link from 'next/link';
import Image from 'next/image';

import useDevice, { DeviceEnum } from '@/components/common/hooks/use-device';
import {
  CmsLandingComponentsMarketingBanner,
  CmsLandingComponentsMarketingBannerItem,
} from '@travel-platform/commons/src/types';

const MarketingBanner: React.FC<CmsLandingComponentsMarketingBanner> = ({ items }) => {
  const isMobile = useDevice() === DeviceEnum.MOBILE;
  return (
    <Box>
      <Grid
        container
        spacing={3}>
        {isMobile ? (
          <React.Fragment>
            <Grid
              item
              xs={12}>
              <Grid
                container
                spacing={2}>
                {!!items?.[0] && (
                  <Grid
                    item
                    xs={6}>
                    <MarketingBannerItem {...items?.[0]} />
                  </Grid>
                )}
                {!!items?.[2] && (
                  <Grid
                    item
                    xs={6}>
                    <MarketingBannerItem {...items?.[2]} />
                  </Grid>
                )}
              </Grid>
            </Grid>

            {!!items?.[1] && (
              <Grid
                item
                xs={12}>
                <MarketingBannerItem {...items?.[1]} />
              </Grid>
            )}
          </React.Fragment>
        ) : (
          <React.Fragment>
            {!!items?.[0] && (
              <Grid
                item
                xs={3}>
                <MarketingBannerItem {...items?.[0]} />
              </Grid>
            )}
            {!!items?.[1] && (
              <Grid
                item
                xs={6}>
                <MarketingBannerItem {...items?.[1]} />
              </Grid>
            )}
            {!!items?.[2] && (
              <Grid
                item
                xs={3}>
                <MarketingBannerItem {...items?.[2]} />
              </Grid>
            )}
          </React.Fragment>
        )}
      </Grid>
    </Box>
  );
};

export default MarketingBanner;

export const MarketingBannerItem: React.FC<CmsLandingComponentsMarketingBannerItem> = ({
  imageUrl,
  url,
}) => {
  return (
    <Box
      sx={{
        img: {
          width: 1,
          maxHeight: {
            xs: '160px',
            md: '252px',
          },
          borderRadius: 2,
        },
      }}>
      <Link href={url || ''}>
        <Image
          width={800}
          height={450}
          src={imageUrl}
          alt=''
        />
      </Link>
    </Box>
  );
};
